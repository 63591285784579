import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "getting-started"
    }}><a parentName="h2" {...{
        "href": "#getting-started",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Getting Started`}</h2>
    <p>{`The easiest way to start a new project is to use the Gatsby starter. It generates a ready-to-use project with all needed dependencies.`}</p>
    <h3 {...{
      "id": "create-your-website"
    }}><a parentName="h3" {...{
        "href": "#create-your-website",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Create your website:`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npx gatsby-cli new website https://github.com/gregberge/smooth-doc-starter
`}</code></pre>
    <h3 {...{
      "id": "change-directories-into-site-folder"
    }}><a parentName="h3" {...{
        "href": "#change-directories-into-site-folder",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Change directories into site folder`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`cd website
`}</code></pre>
    <h3 {...{
      "id": "start-development-server"
    }}><a parentName="h3" {...{
        "href": "#start-development-server",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Start development server`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm run develop
`}</code></pre>
    <p>{`Gatsby will start a hot-reloading development environment accessible by default at http://localhost:8000.`}</p>
    <p>{`Try editing the home page in `}<inlineCode parentName="p">{`pages/index.mdx`}</inlineCode>{`. Saved changes will live reload in the browser.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      